<template>
	<div>
		<div class="text-right">
			<b-button type="button" class="mb-1 btn-light" @click="conversionModal()" style="margin-right: 10px;">{{ $t("환산계수") }}</b-button>
			<b-button type="button" class="mb-1 btn-light" @click="onExcelDownload()">{{ $t("다운로드") }}</b-button>
		</div>
		<b-table
			ref="analysisTable"
			:id="'analysis-table'"
			:class="'text-center rounded'"
			bordered
			borderless
			outlined
			small
			hover
			table-variant="light"
			head-variant="dark"
			fixed
			no-border-collapse
			show-empty
			sticky-header="355px"
			:items="items"
			:fields="fields"
			:empty-text="$t('조회된 결과가 없습니다')"
		>
			<template #empty="scope">
				<h4>{{ scope.emptyText }}</h4>
			</template>
			<template #cell()="data">
				<div v-if="data.field.key == 'regDtTag'" :class="chartValue === data.index ? 'isSelectRow' : ''">{{ analysisLib.localeDateTime(data.value, locale, options.dateType) }}</div>
				<div v-else-if="data.field.key.toLowerCase() === 'eval'" :class="chartValue === data.index ? 'isSelectRow' : ''">{{ data.value }}</div>
				<div v-else :class="chartValue === data.index ? 'isSelectRow' : ''">{{ data.value | numberWithCommas }}</div>
			</template>
			<template #head()="data">
				<!-- <div v-if="data.label.split(' ').length > 1">{{ data.label }}</div> -->
				<div>{{ data.label }}</div>
			</template>
		</b-table>

		<b-modal v-if="showConversionModal" id="conversion-modal" v-model="showConversionModal" :title="$t('환산계수')" centered>
			<h5>{{$t('1차 에너지 환산계수')}}</h5>
			<b-table :items="energyConversionItems" :fields="energyConversionFields"></b-table>

			<h5>{{$t('가스량 환산계수')}}</h5>
			<p>1 (Nm3) = 11.965 (kWh)</p>

			<h5>{{$t('이산화탄소 배출량 환산계수') + ' IPCC 기준'}}</h5>
			<p>1 (kWh) = 0.45970 (kgCO2eq.)</p>

			<p>※ {{$t('2017년 기준 적용')}}</p>

			<template v-slot:modal-footer>
				<b-button variant="primary" @click="conversionModal()">{{$t('확인')}}</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
	// import detailCard from "./GridCard.vue";
	import XLSX from "xlsx";
  import AnalysisLib from "../analysis/AnalysisLib";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["options"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: [],
        analysisLib: AnalysisLib,
				popupMessages,
				showConversionModal: false,
				energyConversionFields: [
					{ key: "category", label: global.xe.$t("구분") },
					{ key: "value", label: global.xe.$t("환산계수") },
				],
				energyConversionItems: [
					{ category: global.xe.$t("연료"), value: 1.1 },
					{ category: global.xe.$t("전력"), value: 2.75 },
					{ category: global.xe.$t("지역난방"), value: 0.728 },
					{ category: global.xe.$t("지역냉방"), value: 0.937 },
				],
			};
		},
		mounted() {},
		computed: {
			locale() {
				return this.$store.state.locale;
			},
			chartValue() {
				return this.$store.getters.getChartSelectValue;
			},
		},
		watch: {
			options() {
				this.setFields();
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
				this.$store.commit("SET_CHART_SELECT_VALUE", null);
			},

			header() {
				this.setFields();
			},

			data() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		methods: {
			setFields() {
				this.fields = [{ key: "regDtTag", label: global.xe.$t("조회기준") }];
				if (this.options !== null) {
					this.options.header.forEach((data) => {
						// this.fields.push({ key: data.divCd, label: `${data.divCdName} ${!data.unit || data.unit === null || data.unit === undefined || data.unit === "" ? "" : `(${data.unit})`}` });
						this.fields.push({
							key: typeof data.divCd === "string" ? data.divCd : data.divCd.toString(),
							label: `${global.xe.$t(data.divCdName)} ${!data.unit || data.unit === null || data.unit === undefined || data.unit === "" ? "" : `(${data.unit})`}`,
						});
					});
				}
				// this.options.debug && console.log('DataGrid Fields: ', this.fields);
			},

			setItems() {
				if (this.options) this.items = this.options.data;
				// this.items[0]._rowVariant = 'danger'
			},

			onExcelDownload() {
				var excelName = this.options.download;

				this.alertQuestion(`${excelName} \r\n ${popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE}`).then((result) => {
					if (!result.value) return;

					var excelData = XLSX.utils.table_to_sheet(document.getElementById("analysis-table")); // table id를 넣어주면된다
					var workBook = XLSX.utils.book_new(); // 새 시트 생성

					XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
					XLSX.writeFile(workBook, excelName); // 엑셀파일 만듬
				});
			},
			conversionModal() {
				this.showConversionModal = !this.showConversionModal;
			},
		},
	};
</script>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}

	.isSelectRow {
		font-weight: 800;
		font-size: 15px;
		color: #348fe2;
	}
</style>
