<template>
	<div class="d-flex flex-row">
		<!-- 조회 조건 -->
		<search-condtion :ref="'searchCondtion'" :searchBoxCond="searchBoxCond" @onChange:timeDsvn="changeTimeDsvn" @setDateFormatter="setDateFormatter" />
		<!-- 조회기간 -->
		<date-picker :ref="'datePicker'" :searchBoxCond="searchBoxCond" :viewMode="viewMode" />
		<!-- 비교대상 -->
		<!-- <compare-combo :ref="'compareCombo'" :searchBoxCond="searchBoxCond" 
        @changeCompareType="changeCompareType" /> -->
	</div>
</template>

<script>
	import searchCondtion from "./Type5SearchCondition.vue";
	import datePicker from "./Type5DatePicker.vue";
	// import compareCombo from "./Type1CompareCombo.vue";
	import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";

	export default {
		components: {
			searchCondtion,
			datePicker,
			// compareCombo
		},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {
				viewMode: "days",
			};
		},
		computed: {},
		created() {
			let timeDsvn = this.searchBoxCond.conditions.timeDsvn;
			if (timeDsvn.toLowerCase() !== "day" || timeDsvn.toLowerCase() !== "month") {
				this.searchBoxCond.conditions.timeDsvn = "Day";
				this.setDateFormatter();
			}
		},
		mounted() {},
		updated() {},
		destroyed() {},
		methods: {
      checkDateRange(param) {
				let message = SearchUtils.searchParamValCheck(param);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

				message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay, diffInfo.from);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

        return true;
			},
			// searchbox 조회 조건 event
			changeTimeDsvn(timeDsvn) {
				this.searchBoxCond.conditions.timeDsvn = timeDsvn;
				this.searchBoxCond.conditions.compareType = "none";

				this.viewMode = `${timeDsvn.toLowerCase()}s`;
				console.log(timeDsvn);
				if (timeDsvn === "Day") {
					this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
				}

				if (timeDsvn === "Month") {
					let year = this.searchBoxCond.datePicker.toDate.getFullYear();
					let month = this.searchBoxCond.datePicker.toDate.getMonth();
					this.searchBoxCond.datePicker.fromDate = new Date(year, month, 1);
          this.searchBoxCond.datePicker.toDate = new Date(year, month + 1, 0);
				}

				this.setDateFormatter();
			},
			changeCompareType() {
				this.$refs.datePicker.calcCompareDate();
			},
			setDateFormatter() {
				if (this.$refs.datePicker) {
					this.$refs.datePicker.setDateFormatter();
					this.$refs.datePicker.calcDate(this.searchBoxCond.conditions.timeDsvn, new Date());
				}
			},
		},
	};
</script>

<style scoped></style>
