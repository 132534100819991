<template>
	<div class="EnergyPredictionChart">
		<div class="col-xl-12 col-lg-12 innerBox MixChart bg-white" :style="{ padding: '5px' }">
			<mix-chart ref="chart" :chartData="chartDatas" :options="chartOptions" :style="{ height: '100%', padding: '15px' }" />
		</div>
	</div>
</template>

<script>
	import MixChart from "@src/components/vue-chartjs/Charts/MixChart.js";
	import AnalysisLib from "../AnalysisLib";

	export default {
		name: "EnergyPredictionChart",
		props: ["searchResult"],
		components: {
			MixChart,
		},
		data() {
			return {
				chartOptions: null,
				chartLabels: null,
				chartDatas: null,
        timeTitle: "",
			};
		},
		created() {
			this.reset();
			this.setData();
		},
		watch: {
			searchResult() {
				this.reset();
				this.setData();
			},
		},
		methods: {
			reset() {
				this.chartOptions = null;
				this.chartLabels = null;
				this.chartDatas = null;
        this.timeTitle = "";
			},
			setData() {
				this.setOptions();
				this.setLabels();
				this.setDatas();

        if(this.searchResult.options.conditions.timeDsvn.toLowerCase() === 'month'){
          this.timeTitle = global.xe.$t("일별");
        } else if(this.searchResult.options.conditions.timeDsvn.toLowerCase() === 'year'){
          this.timeTitle = global.xe.$t("월별");
        } else if(this.searchResult.options.conditions.timeDsvn.toLowerCase() === 'yearly'){
          this.timeTitle = global.xe.$t("연도별");
        }
			},
			setOptions() {
				this.chartOptions = {
					responsive: true,
					maintainAspectRatio: false,
					animation: {
						duration: 1000,
						easing: "easeOutQuart",
					},
					hoverMode: "index",
					legend: {
						display: true,
						align: "end",
						labels: {
							usePointStyle: true,
						},
					},
					title: {
						display: true,
						align: "start",
						position: "top",
						// text: this.timeTitle + " 에너지 소비량 예측",
						fontSize: 20,
						fontColor: "#000000",
					},

					layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
					hover: {
						intersect: false,
					},
					scales: {
						xAxes: [
							{
								id: "x-axis-0",
								stacked: false,
								ticks: {
									autoSkip: true,
									fontColor: "#000000",
									fontSize: 12,
									maxTicksLimit: 10,
									maxRotation: 0,
								},
							},
						],
						yAxes: [
							{
								id: "Left_yAxis",
								display: true,
								position: "left",
								stacked: false,
								ticks: {
                  maxTicksLimit: 10,
									beginAtZero: true,
									fontColor: "#000000",
									fontSize: 10,
								},
							},
						],
					},
				};
			},
			setDatas() {
				let datasets = [];
				// let datakeys = [];

      
				// by badblock
				let that = this;
				this.searchResult.data.header.forEach((header) => {
          if(header.divCd === 'Eval') return;

					let chartData = {
						label: header.divCdName,
						type: "bar",
						maxBarThickness: 50,
						backgroundColor: AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, header.divCd),
						borderWidth: 1,
						fill: false,
						data: that.searchResult.data.baseData.map((item) => item[header.divCd]),
					};

					datasets.push(chartData);
				});

				this.chartDatas = {
					datasets: datasets,
					labels: this.chartLabels,
				};

				// by Kim
				/* for (let i = 0; i < this.predictData.header.length; i++) {

        if (this.predictData.header[i].chartVisiable == "Y") {
          datakeys.push(this.predictData.header[i].divCd);
        }
      }

      for (let i = 0; i < datakeys.length; i++) {
        
        
        let defaultForm = {}

        if (datakeys[i] === 'Pred'){
          defaultForm = {
            type: "bar",
            label: this.predictData.header[i].divCdName,
            data: [],
            backgroundColor: this.cardColor[this.predictData.header[i].divCd],
            // borderColor: borderColor,
            borderWidth: 1,
            fill: false,
          };
        }else{
          defaultForm = {
            type: "bar",
            label: this.predictData.header[i].divCdName,
            data: [],
            backgroundColor: this.cardColor[this.predictData.header[i].divCd],
            // borderColor: borderColor,
            borderWidth: 1,
            fill: false,
          };
        }

        // console.log('predict datakeys : ' , datakeys[0]);

        for (let j = 0; j < this.predictData.baseData.length; j++) {
          let data = this.predictData.baseData[j];
          if (data[datakeys[i]] === "-") {
            defaultForm.data.push("-");
          } else {
            defaultForm.data.push(data[datakeys[i]]);
          }
        }

        datasets.push(defaultForm);
      } 
      
      this.chartDatas = {
        datasets: datasets,
        labels: this.chartLabels,
      }; */
			},
			setLabels() {
				this.chartLabels = [];

				for (let i = 0; i < this.searchResult.data.baseData.length; i++) {
					this.chartLabels.push(AnalysisLib.localeDateTime(this.searchResult.data.baseData[i].regDtTag, this.$store.state.locale, this.searchResult.options.conditions.timeDsvn));
				}
			},
		},
	};
</script>

<style lang="scss">
	.EnergyPredictionChart {
		margin-top: 10px;
		.MixChart {
			height: 300px;
		}
	}
</style>
