<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <b-button variant="primary" @click="onSearch()" :style="{ width:'50px', height: '100%', padding:0}" > <span>{{$t("조회")}}</span> </b-button> 
        <!-- <div class="d-flex" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;"> -->
            <!-- <b-button squared class="fa-3x" variant="#ffffff" size="md" @click="onSearch()" :style="{ width:'35px', height: '100%', padding:0, color:'#348fe2'}" > -->
            <!-- <span>{{"조회"}}</span> -->
            <!-- </b-button>  -->
        <!-- </div> -->
    </div>
</template>

<script>


export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted(){},
  updated(){},
  destroyed(){},
  methods: {
    onSearch(){
      this.$emit("search");
    }
  },
};
</script>

<style scoped>

</style>