<template>
  <div class="row cardArea">
    <!-- <div class="innerBox col-md-12" :class="locale === 'en' ? 'col-xl-3' : 'col-xl-2'" v-for="(cardOption, index) in options" :key="index"> -->
    <div class="innerBox col-md-12" :class="'col-xl-2'" v-for="(cardOption, index) in options" :key="index">
      <card-box ref="cardBox" :options="cardOption" />
    </div>
  </div>

</template>

<script>

// Default CSS
import "element-ui/lib/theme-chalk/index.css";
import CardBox from "@views/component/cardBox/analysis/BasicCard-v2.vue";

export default {
  components: {
    CardBox,
  },
  props: ["options"],
  data() {
    return {
    } 
  },
  computed:{
    locale(){
      return this.$store.state.locale;
    }
  },
  watch: { },
  created() { },
  mounted() { },
}

</script>

<style scoped>

</style>