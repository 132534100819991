<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{ $t("조회기간") }}</span>
		</div>
		<div class="p-l-5">
			<div class="d-flex flex-row">
				<div>
					<date-picker
						placeholder="Start Date"
						:value="searchBoxCond.datePicker.fromDate"
						:style="searchBoxCond.conditions.timeDsvn == '' ? { width: '140px' } : { width: '135px' }"
						:config="{ format: this.fromFormat, viewMode: viewMode, locale: locale, ignoreReadonly: true }"
						readonly="readonly"
						@dp-hide="onFromDatePicker($event.target.value)"
					/>
				</div>
				<div class="pt-2 p-l-5 p-r-5">
					<span> ~ </span>
				</div>
				<div class="p-r-5">
					<date-picker
						placeholder="End Date"
						:value="searchBoxCond.datePicker.toDate"
						:style="searchBoxCond.conditions.timeDsvn == '' ? { width: '140px' } : { width: '135px' }"
						:config="{ format: this.toFormat, viewMode: viewMode, locale: locale, ignoreReadonly: true }"
						readonly="readonly"
						@dp-hide="onToDatePicker($event.target.value)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchUtils from "../SearchUtils";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		name: "Type1DatePicker",
		components: {},
		props: ["searchBoxCond", "viewMode"],
		watch: {},
		data() {
			return {
				config: {},
				popupMessages,
			};
		},
		computed: {
			locale() {
				return this.$store.state.locale === "kr" ? "ko" : this.$store.state.locale;
			},

			fromFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.fromFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.fromFormatter = newValue;
				},
			},
			toFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.toFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.toFormatter = newValue;
				},
			},
		},
		created() {
			console.log("::: init created::::");

			if (this.searchBoxCond.conditions.timeDsvn === "Day") {
				this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
			}
		},
		mounted() {},
		destroyed() {},

		methods: {
			calcDate(timeDsvn, now) {
				let date = null;

				switch (timeDsvn) {
					case "Day":
						date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						break;
					case "Month":
						date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						break;
					default:
						date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						break;
				}

				return date;
			},

			calcCompareDate() {
				console.log(this.searchBoxCond);
				let yy = 0,
					mm = 0,
					dd = 0;
				let fromDate = new Date(this.searchBoxCond.datePicker.fromDate);
				let toDate = new Date(this.searchBoxCond.datePicker.toDate);

				if (this.searchBoxCond.conditions.compareType === "previousDay") dd = 1;
				else if (this.searchBoxCond.conditions.compareType === "previousMonth") mm = 1;
				else if (this.searchBoxCond.conditions.compareType === "previousYear") yy = 1;

				this.searchBoxCond.datePicker.compFromDate = new Date(fromDate.getFullYear() - yy, fromDate.getMonth() - mm, fromDate.getDate() - dd);
				this.searchBoxCond.datePicker.compToDate = new Date(toDate.getFullYear() - yy, toDate.getMonth() - mm, toDate.getDate() - dd);
			},

			onFromDatePicker(value) {
				//@dp-change로 이벤트 동작 시 무한 루프
				// let now = new Date(this.searchBoxCond.datePicker.fromDate);
				let now = new Date(value);
				this.searchBoxCond.datePicker.fromDate = this.calcDate(this.searchBoxCond.conditions.timeDsvn, now);
				// if (this.searchBoxCond.conditions.timeDsvn === "Day") this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
				// if (this.searchBoxCond.conditions.timeDsvn === 'Month') {
				//   let year = this.searchBoxCond.datePicker.fromDate.getFullYear();
				//   let month = this.searchBoxCond.datePicker.fromDate.getMonth();
				//   this.searchBoxCond.datePicker.toDate = new Date(year, month+1, 0);
				// }

				let result = SearchUtils.dateLengthCheck(this.searchBoxCond.conditions.timeDsvn, this.searchBoxCond.datePicker.fromDate, this.searchBoxCond.datePicker.toDate);

				if (result.diff < 0) {
					this.alertNoti(popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE).then(() => {
						this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
					});

					return;
				}

				let message = SearchUtils.wrongDateMessage(this.searchBoxCond.conditions.timeDsvn, result.diffDay, result.from);

				if (message !== "") {
					this.alertNoti(message).then(() => {
						this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
					});
				}

				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.fromDate: ", this.searchBoxCond.datePicker.fromDate);
				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compFromDate: ", this.searchBoxCond.datePicker.compFromDate);
			},

			onToDatePicker(value) {
				//@dp-change로 이벤트 동작 시 무한 루프
				// let now = new Date(this.searchBoxCond.datePicker.fromDate);
				let now = new Date(value);
				this.searchBoxCond.datePicker.toDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, now);
				// if (this.searchBoxCond.conditions.timeDsvn === "Day") this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
				if (this.searchBoxCond.conditions.timeDsvn === "Month") {
					this.searchBoxCond.datePicker.toDate = new Date(this.searchBoxCond.datePicker.toDate.getFullYear(), this.searchBoxCond.datePicker.toDate.getMonth() + 1, 0);
				}

				let result = SearchUtils.dateLengthCheck(this.searchBoxCond.conditions.timeDsvn, this.searchBoxCond.datePicker.fromDate, this.searchBoxCond.datePicker.toDate);

				if (result.diff < 0) {
					this.alertNoti(popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE).then(() => {
						this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
					});

					return;
				}

				let message = SearchUtils.wrongDateMessage(this.searchBoxCond.conditions.timeDsvn, result.diffDay, result.from);

				if (message !== "") {
					this.alertNoti(message).then(() => {
						this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
					});
				}

				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.toDate: ", this.searchBoxCond.datePicker.toDate);
				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compToDate: ", this.searchBoxCond.datePicker.compToDate);
			},

			setDateFormatter() {
				switch (this.searchBoxCond.conditions.timeDsvn) {
					case "Hour": // 15Min Sum
						this.fromFormat = "YYYY-MM-DD";
						this.toFormat = "YYYY-MM-DD";
						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;

					case "Day": // Hour Sum
						this.fromFormat = "YYYY-MM-DD";
						this.toFormat = "YYYY-MM-DD";
						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;

					case "Month": // Day Sum
						this.fromFormat = "YYYY-MM";
						this.toFormat = "YYYY-MM";
						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
						break;

					case "Year": // Month Sum
						this.fromFormat = "YYYY-MM";
						this.toFormat = "YYYY-MM";
						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMM";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMM";
						break;

					default:
						this.fromFormat = "YYYY-MM-DD";
						this.toFormat = "YYYY-MM-DD";
						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
				}
			},
		},
	};
</script>

<style scoped>
	.form-control[readonly] {
		background: #fff;
		color: #2d353c;
		opacity: 1;
	}
</style>
