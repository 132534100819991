<template>
  <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
      <div class="pt-2 p-l-5">
        <span>{{$t('조회유형')}}</span>
      </div>
      <div class="p-l-5 p-r-5">
        <div class="btn-group">
            <button :ref="'Day'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Day' }" @click="onClick('Day')">{{$t('일간')}}</button>
            <button :ref="'Month'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Month' }" @click="onClick('Month')">{{$t('월간')}}</button>
          </div>
      </div>
    </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";

export default {
components: {},
props: ["searchBoxCond"],
watch: {},
data() {
  return {
  };
},
computed: {
  
},
created() {

},
mounted(){
  // this.activeTimeDsvnSetting();
  this.$emit("onChange:timeDsvn", this.searchBoxCond.conditions.timeDsvn);

},
updated(){
},
destroyed(){
},
methods: {
  /*
  activeTimeDsvnSetting(){
    this.searchBoxCond.conditions.compareType = "previousDay";
    switch (this.searchBoxCond.conditions.timeDsvn) {
      case "Day":
        this.searchBoxCond.conditions.compareType = "previousDay";
        break;
      case "Hour":
        this.searchBoxCond.conditions.compareType = "previousDay";
        break;
      case "Week":
        this.searchBoxCond.conditions.compareType = "previousMonth";
        break;
      case "Month":
        this.searchBoxCond.conditions.compareType = "previousMonth";
        break;
      case "Year":
        this.searchBoxCond.conditions.compareType = "previousYear";
        break;
      case "Yearly":
        this.searchBoxCond.conditions.compareType = "none";
        break;
      default : 
        this.searchBoxCond.conditions.timeDsvn = "Hour";
        this.searchBoxCond.conditions.compareType = "none";
        break;
    }  
    this.$emit("setDateFormatter");
  },
  */
  onClick(type) {
    this.$emit("onChange:timeDsvn",type);
  },
},
};
</script>

<style scoped>

</style>