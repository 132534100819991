<template>
	<!-- 에너지원 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{$t('예측값 유형')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select v-if="predicPTList === null" class="form-control" ref="predicPtList" v-model="ptAddr" @change="onSmallCodeSet" disabled>
				<slot name="customOption"></slot>

				<option value="">{{$t('값이 존재하지 않습니다')}}</option>
			</select>
			<select v-else class="form-control" ref="predicPtList" v-model="ptAddr" @change="onSmallCodeSet">
				<slot name="customOption"></slot>

				<option v-for="(pt, index) in predicPTList" :key="index" :value="pt.ptAddr">
					{{ pt.ptName }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";

export default {
	components: {},
	props: ["energySourceCode", "enableTotal"],
	watch: {
		energySourceCode() {
			this.loadData();
		},
	},
	data() {
		return {
			ptAddr: "",
			predicPTList: null,
		};
	},
	computed: {},
	created() { },
	mounted() {
		this.loadData();
	},
	updated() { },
	destroyed() { },
	methods: {
		loadData() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			backEndApi.cstnPredic.itemSelectBox(this.energySourceCode).then(({ data }) => {
				this.predicPTList = data;
				this.ptAddr = data[0].ptAddr;
				this.$emit("ready:data", this.getValue());
			}).catch(() => {
				this.predicPTList = null;
				this.ptAddr = "";

				this.$emit("ready:data", this.getValue());
			});
		},

		getValue() {
			return this.energySourceCode;
		},

		getPTList() {
			return this.predicPTList === null ? [] : this.predicPTList;
		},

		onSmallCodeSet() {
			console.log(this.energySourceCode);
			this.$emit("ready:data", this.getValue());
		},
	},
};
</script>

<style scoped></style>
